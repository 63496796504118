window.gaSlim = function gaSlim() {
    if (window.ga2 !== undefined)
        return;

    try {
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments);},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');



        ga('create', 'UA-63842341-1', 'auto');
        ga('send', 'pageview', { 'dimension2': 'user' });

        ga('create', 'UA-63842341-4', 'auto', 'main');

        $('li a[href="https://marketplace.servicetitan.com/"]').click(function (e) {
            ga('main.send', {
                hitType: 'event',
                eventCategory: 'All',
                eventAction: 'clicked_marketplace_from_dropdown',
                eventLabel: window.AppUser.UserRole,
            });
        });
      

    } catch (e) {
        console.log(e);
    }
};